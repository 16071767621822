<template>
 <p v-for="(note, note_i) in notes" v-bind:key="note">
  {{note_i}}: {{note}}
 </p>
</template>

<script>
 import {unauthFetch, authFetch} from '@/lib/api.js' 
 export default {
     name: 'NoteList',
     data(){
         return {
             group: this.$route.params.group,
             notes: []
         }
     },
     mounted(){
         console.log("Beafore mount", {group: this.group, testisi: 123})
         authFetch('/notes/notes', 'GET', {group: this.group, testisi: 123}).then((response) => {
             console.log(response)
         }).catch((error) => {
             console.log(error.response)
         })
     }
 }
</script>
